/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .tree-selector ul li {
  list-style: none;
  cursor: pointer;
}
.altai-theme-admin div.treeview {
  min-width: 100px;
  min-height: 100px;
  max-height: 256px;
  overflow: auto;
  padding: 4px;
  margin-bottom: 20px;
  border: solid 1px;
  border-radius: 4px;
}
.altai-theme-admin div.treeview ul:first-child:before {
  display: none;
}
.altai-theme-admin .treeview,
.altai-theme-admin .treeview ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.altai-theme-admin .treeview ul {
  margin-left: 1em;
  position: relative;
}
.altai-theme-admin .treeview ul ul {
  margin-left: 0.5em;
}
.altai-theme-admin .treeview ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-left: 1px solid;
  /* creates a more theme-ready standard for the bootstrap themes */
  bottom: 15px;
}
.altai-theme-admin .treeview li {
  margin: 0;
  padding: 0 12px;
  line-height: 2em;
  position: relative;
}
.altai-theme-admin .treeview ul li:before {
  content: "";
  display: block;
  width: 10px;
  height: 0;
  border-top: 1px solid;
  margin-top: -1px;
  position: absolute;
  top: 1em;
  left: 0;
}
.altai-theme-admin .tree-indicator {
  margin-right: 5px;
  cursor: pointer;
}
.altai-theme-admin .treeview li {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.altai-theme-admin .treeview li button,
.altai-theme-admin .treeview li button:active,
.altai-theme-admin .treeview li button:focus {
  text-decoration: none;
  color: inherit;
  border: none;
  background: transparent;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  outline: 0;
}
